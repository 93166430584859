import { createReducer } from "@reduxjs/toolkit";
import {
  getSpecialProductsAction,
  getSpecialProductsFailAction,
  getSpecialProductsSuccessAction,
} from "../actions/specialProducts";

const initialState = {
  data: [],
  loading: false,
  count: 0,
  thisPage: 1,
  nextPage: 1,
};

export const specialProductsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSpecialProductsAction, (state) => {
      state.loading = true;
    })
    .addCase(getSpecialProductsSuccessAction, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.count = action.payload.count;
      state.thisPage = action.payload.thisPage;
      state.nextPage = action.payload.nextPage;
    })
    .addCase(getSpecialProductsFailAction, (state) => {
      state.loading = false;
    });
});

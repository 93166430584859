import { CREATE_USER, UPDATE_USER } from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(CREATE_USER, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(UPDATE_USER, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
      state.error = false;
    });
});

export default userReducer;

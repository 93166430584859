import { createAction } from "@reduxjs/toolkit";
import {
  createSpecialProductService,
  deleteSpecialProductService,
  editSpecialProductService,
  listSpecialProductsService,
  singleSpecialProductService,
} from "../../../services/specialProducts";
import { setError, setSuccessMessage } from "../../reducers/alert";

export const getSpecialProductsAction = createAction(
  "GET_SPECIAL_PRODUCTS_ACTION"
);
export const getSpecialProductsSuccessAction = createAction(
  "GET_SPECIAL_PRODUCTS_SUCCESS_ACTION"
);
export const getSpecialProductsFailAction = createAction(
  "GET_SPECIAL_PRODUCTS_FAIL_ACTION"
);
export const getSpecialProductsListAction =
  ({ page, limit, filters }) =>
  async (dispatch) => {
    try {
      dispatch(getSpecialProductsAction());
      const { products, thisPage, nextPage, count, error } =
        await listSpecialProductsService({
          page,
          limit,
          filters,
        });

      if (error) {
        dispatch(getSpecialProductsFailAction());
        dispatch(setError(error));
        return;
      }

      dispatch(
        getSpecialProductsSuccessAction({
          data: products,
          thisPage,
          nextPage,
          count,
        })
      );
    } catch (error) {
      dispatch(getSpecialProductsFailAction());
      dispatch(setError("Network Error"));
    }
  };

export const createSpecialProductAction =
  ({ sendData, callback }) =>
  async (dispatch) => {
    try {
      const editData = {
        ...sendData,
        isForMerchant: true,
        providerId: 2000,
        brandId: 1,
        minFaceValue: sendData.unitPrice,
        maxFaceValue: sendData.unitPrice,
        fixedValue: true,
        unitPrice: sendData.unitPrice,
        displayPrice: sendData.unitPrice,
        providerCount: 1,
        currency: "EGP",
        loyalityPointsBackPerUnit: 1,
      };
      const { data, message, error } = await createSpecialProductService({
        sendData: editData,
      });

      if (error) {
        dispatch(setError(error));
        return;
      }

      dispatch(
        setSuccessMessage(message || "Special product created successfully")
      );

      callback && callback(data);
    } catch (error) {
      dispatch(setError("Network Error"));
    }
  };

export const editSpecialProductAction =
  ({ sendData, callback }) =>
  async (dispatch) => {
    try {
      const { data, message, error } = await editSpecialProductService({
        sendData,
      });

      if (error) {
        dispatch(setError(error));
        return;
      }

      dispatch(
        setSuccessMessage(message || "Special product updated successfully")
      );

      callback && callback(data);
    } catch (error) {
      dispatch(setError("Network Error"));
    }
  };

export const deleteSpecialProductAction =
  ({ id, callback }) =>
  async (dispatch) => {
    try {
      const { message, error } = await deleteSpecialProductService(id);

      if (error) {
        dispatch(setError(error));
        return;
      }

      dispatch(
        setSuccessMessage(message || "Special product deleted successfully")
      );

      callback && callback();
    } catch (error) {
      dispatch(setError("Network Error"));
    }
  };

export const getSingleSpecialProductAction =
  ({ id, callback }) =>
  async (dispatch) => {
    try {
      const { data, error } = await singleSpecialProductService(id);

      if (error) {
        dispatch(setError(error));
        return;
      }

      callback && callback(data);
    } catch (error) {
      dispatch(setError("Network Error"));
    }
  };

import {
  delRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../connection/network";

const specialProductsApi = {
  list: "admin/merchant-products",
  create: "admin/merchant-products",
  edit: "admin/merchant-products",
  delete: "admin/merchant-products",
  single: "admin/merchant-products",
};

export const listSpecialProductsService = async ({ page, limit, filters }) => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: {
        products,
        meta: { thisPage, nextPage, count },
      },
    } = await getRequest({
      token,
      query: { page, limit, ...filters },
      endPoint: specialProductsApi.list,
    });

    return {
      products,
      thisPage: parseInt(thisPage),
      nextPage: parseInt(nextPage),
      hasMore: products?.length === limit,
      count,
    };
  } catch (error) {
    const sError = error?.response?.data?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const singleSpecialProductService = async (id) => {
  try {
    const token = localStorage.getItem("token");
    let { data, message } = await getRequest({
      endPoint: specialProductsApi.single,
      params: parseInt(id),
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const createSpecialProductService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let { data, message } = await postRequest({
      body: {
        ...sendData,
      },
      endPoint: specialProductsApi.create,
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const editSpecialProductService = async ({ sendData }) => {
  try {
    const token = localStorage.getItem("token");
    let { data, message } = await putRequest({
      body: {
        ...sendData,
      },
      endPoint: specialProductsApi.edit,
      token,
      params: sendData?.id,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.message || "Unknown error occurred";
    return { error: sError };
  }
};

export const deleteSpecialProductService = async (id) => {
  try {
    const token = localStorage.getItem("token");
    let { data, message } = await delRequest({
      endPoint: specialProductsApi.delete,
      params: parseInt(id),
      token,
    });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.message || "Unknown error occurred";
    return { error: sError };
  }
};

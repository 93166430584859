import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { clearError, clearSuccessMessage } from "store/reducers/alert";

import { useDispatch, useSelector } from "react-redux";

const GenericAlert = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.alert.errorMessage);
  const successMessage = useSelector((state) => state.alert.successMessage); // Get success message from state

  const handleClose = () => {
    dispatch(clearError());
    dispatch(clearSuccessMessage()); // Clear success message on close
  };

  return (
    <Snackbar
      open={!!errorMessage || !!successMessage} // Check if either error or success message is present
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={errorMessage ? "error" : "success"} // Determine severity based on presence of error or success message
        onClose={handleClose}
      >
        {errorMessage || successMessage}{" "}
      </MuiAlert>
    </Snackbar>
  );
};

export default GenericAlert;

import axios from "axios";
const baseURL =
  process.env.REACT_APP_BASE_API_URL || "https://api.neogamerz.com";
const postRequest = async ({ body, query, params, endPoint, token }) => {
  return await axios.post(
    `${baseURL}/${endPoint}/${params ? params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token
          ? { Authorization: token ? `Bearer ${token}` : undefined }
          : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

const getRequest = async ({ query, params, endPoint, token }) => {
  return await axios.get(`${baseURL}/${endPoint}/${params ? params : ""}`, {
    params: query,
    headers: {
      ...(token
        ? { Authorization: token ? `Bearer ${token}` : undefined }
        : {}),
      "Content-Type": "application/json",
      "accept-language": localStorage?.getItem("lng") || "en",
    },
  });
};

const delRequest = async ({ query, params, endPoint, token }) => {
  return await axios.delete(`${baseURL}/${endPoint}${params ? params : ""}`, {
    params: query,
    headers: {
      ...(token
        ? { Authorization: token ? `Bearer ${token}` : undefined }
        : {}),
      "Content-Type": "application/json",
    },
  });
};

const putRequest = async ({ body, query, params, endPoint, token }) => {
  return await axios.put(
    `${baseURL}/${endPoint}/${params ? params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token
          ? { Authorization: token ? `Bearer ${token}` : undefined }
          : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

const patchRequest = async ({ body, query, params, endPoint, token }) => {
  return await axios.patch(
    `${baseURL}/${endPoint}/${params ? params : ""}`,
    body,
    {
      params: query,
      headers: {
        ...(token
          ? { Authorization: token ? `Bearer ${token}` : undefined }
          : {}),
        "Content-Type": "application/json",
      },
    }
  );
};

export {
  baseURL,
  delRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
};

import { grey } from "@mui/material/colors";

const getTheme = (isRTL) => ({
  palette: {
    primary: {
      "main": "#13205D",
      "lighter": "#4B5990",
      "light": "#404C82",
      "dark": "#0D163C",
      "darker": "#0A1029",
      contrastText: "#FFFFFF",
    },
  },
  typography: {
    allVariants: {
      fontFamily: isRTL === "rtl" ? "LamaSans" : "Lato",
      textTransform: "none",
      "--bs-font-sans-serif":
        "system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
      "--bs-font-monospace":
        "SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
      "--bs-body-font-family": "Almarai,sans-serif",
    },
    h1: { fontWeight: 800 },
    h2: { fontWeight: 800 },
    h3: { fontWeight: 800 },
    h4: { fontWeight: 800 },
    h5: { fontWeight: 800 },
    h6: { fontWeight: 700 },
    body1: { fontWeight: 400 },
    body2: { fontWeight: 400 },
    subtitle1: { fontWeight: 700 },
    subtitle2: { fontWeight: 400 },
    caption: { fontWeight: 400 },
    button: { fontWeight: 700 },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@media (max-width: 600px)": {
          html: { marginRight: "0" },
          "::-webkit-scrollbar": { display: "none" },
        },
        "::-webkit-scrollbar": { width: "8px" },
        "::-webkit-scrollbar-track": { background: "transparent" },
        "::-webkit-scrollbar-thumb": {
          background: grey[800], // Customize scrollbar color
          borderRadius: "8px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: grey[800], // Customize scrollbar hover color
        },
        "*": { boxSizing: "border-box" },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: { margin: 0, padding: 0, width: "100%", height: "100%" },
        "#root": { width: "100%", height: "100%" },
        img: {
          maxWidth: "100%",
          display: "inline-block",
          verticalAlign: "bottom",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "1px solid #E5E7EB",
          borderRadius: "8px",
          boxShadow: "none", // Remove all shadows
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Remove all shadows from Cards
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none", // Remove all shadows from AppBars
          border: "none"
        },
      },
    },
  },
});

export default getTheme;

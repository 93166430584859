import { getRequest, postRequest } from "../../../connection/network";
import {
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  USER_LOADED,
} from "../types";
const authApis = {
  me: "auth/me",
  login: "auth/login-admin",
};
export const logoutAction = () => (dispatch) => {
  dispatch({ type: LOGOUT_SUCCESS });
};

export const getUser = async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    if (!token) return dispatch({ type: AUTH_ERROR });

    const res = await getRequest({
      token,
      endPoint: authApis.me,
    });

    const user = res.data;

    dispatch({
      type: USER_LOADED,
      payload: {
        token,
        user,
      },
    });

    return { token };
  } catch (error) {
    dispatch({ type: AUTH_ERROR });
    return { token: null };
  }
};

export const login =
  ({ email, password, pageId, companyId }) =>
  (dispatch) => {
    return postRequest({
      body: {
        email,
        password,
        pageId: pageId || undefined,
        companyId: companyId || undefined,
      },
      endPoint: authApis.login,
    })
      .catch((error) => {
        dispatch({
          type: AUTH_ERROR,
          payload: "Invalid mobile, email or password",
        });
      })
      .then((res) => {
        localStorage.setItem("token", res.data.authToken);
        const { user, authToken } = res.data;

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            token: authToken,
            user,
          },
        });
      });
  };
